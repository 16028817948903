import { Suspense } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  FullPageError,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import League from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants/constants';
import AppRoutes from './components/app-routes.component';
import { config, routesConfig } from './config';

League.overwriteRoutePaths(routesConfig);

export function App() {
  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<ErrorState />}>
        <Suspense fallback={<LoadingIndicator />}>
          {useOnlineStatus().isOffline && (
            <FullPageError route={ROUTE_PATHS.HOME} />
          )}
          {useOnlineStatus().isOnline && (
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
