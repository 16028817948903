import {
  Button,
  Checkbox,
  Fieldset,
  FormField,
  Radio,
  TextInput,
} from '@leagueplatform/genesis-core';
import {
  ControlledFieldset,
  FieldValues,
  FormProvider,
  useForm,
  useInput,
  useWatch,
} from '@leagueplatform/web-common';
import { useCallback } from 'react';
import { useSelector } from '../hooks/useSelector';
import { useSend } from '../hooks/useSend';
import { EnrollmentPeriod, EnrollmentPeriodQuestionAnswer } from '../types';

type EnrollmentQuestion = NonNullable<EnrollmentPeriod['questions']>[number];

type YearSelectionProps = {
  years: number[];
};

/**
 * Renders a year selector and sends the appropriate event to the state machine
 * upon selection.
 */
const YearSelection = ({ years }: YearSelectionProps) => {
  const { unregister } = useForm();

  const onChange = useCallback(() => {
    unregister('questions');
  }, [unregister]);
  return (
    <ControlledFieldset
      id="year"
      name="year"
      inputOptions={{
        required: true,
        onChange,
      }}
      legend="Year"
    >
      {years.map((year) => (
        <Radio
          key={year}
          id={`years-${year}`}
          name="years"
          value={year}
          label={year}
        />
      ))}
    </ControlledFieldset>
  );
};

type QuestionDateProps = {
  questionId: EnrollmentQuestion['id'];
  label: NonNullable<EnrollmentQuestion['dateLabel']>;
};

/**
 * Renders a single date input field for a given question. Shows/hides itself
 * based on whether the corrsponding elibility question checkbox is checked.
 */
const QuestionDate = ({ questionId, label }: QuestionDateProps) => {
  const isChecked = useWatch({ name: `questions.${questionId}.answer` });

  const { field, inputValidationState } = useInput(
    `questions.${questionId}.date`,
    {
      required: isChecked,
    },
  );

  return (
    isChecked && (
      <FormField
        {...field}
        {...inputValidationState}
        id={`questions.${questionId}.date`}
        label={label}
      >
        <TextInput type="date" />
      </FormField>
    )
  );
};

/**
 * Renders a single enrollment period eligibility question.
 */
const Question = ({
  id,
  label,
  requiresDate,
  dateLabel,
}: EnrollmentQuestion) => {
  const { field, inputValidationState } = useInput(`questions.${id}.answer`);

  return (
    <>
      <Checkbox {...field} {...inputValidationState} id={id} label={label} />
      {requiresDate && <QuestionDate questionId={id} label={dateLabel!} />}
    </>
  );
};

/**
 * The shape of the "field values" object for questions as assembled
 * by `react-hook-form`.
 */
type QuestionsFieldValues = {
  [key: EnrollmentQuestion['id']]:
    | {
        answer: false;
      }
    | {
        answer: true;
        date?: string;
      };
};

type QuetionsProps = {
  questions: EnrollmentPeriod['questions'];
};

/**
 * Renders the array of enrollment period eligibility questions as checkboxes
 * and optional accompanying date input fields.
 */
const Questions = ({ questions }: QuetionsProps) => {
  const { field, inputValidationState } = useInput('questions', {
    validate: (questionStates: QuestionsFieldValues) =>
      !Object.values(questionStates).some(({ answer }) => answer)
        ? 'at least one'
        : undefined,
  });

  return (
    <>
      <Fieldset
        id="questions"
        legend="Please read the following statements carefully and check each box that applies to you:"
        {...field}
        {...inputValidationState}
      >
        {questions?.map((question) => (
          <Question key={question.id} {...question} />
        ))}
      </Fieldset>
      <Button type="submit">Submit</Button>
    </>
  );
};

/**
 * The shape of the "field values" object for the entire form as assembled by
 * `react-hook-form`.
 */
type FormFieldValues = {
  year?: string;
  questions: QuestionsFieldValues;
};

const Fields = () => {
  const enrollmentPeriods = useSelector(
    (snapshot) => snapshot.context.enrollmentPeriods!,
  );

  const hasMultipleEnrollmentPeriods = enrollmentPeriods.length > 1;

  const enrollmentYears = enrollmentPeriods.map(
    ({ enrollmentYear }) => enrollmentYear,
  );

  const year = useWatch<FormFieldValues, 'year'>({ name: 'year' });

  const questions = hasMultipleEnrollmentPeriods
    ? enrollmentPeriods.find(
        ({ enrollmentYear }) => enrollmentYear === Number(year),
      )?.questions
    : enrollmentPeriods[0].questions;

  return (
    <>
      {hasMultipleEnrollmentPeriods && (
        <YearSelection years={enrollmentYears} />
      )}
      {questions && <Questions questions={questions} />}
    </>
  );
};

/**
 * Shown when the user is ready to answer eligibility questions.
 */
export const EligibilityQuestionsView = () => {
  const send = useSend();

  const submitHandler = useCallback(
    (fieldValues: FieldValues) => {
      const { year, questions } = fieldValues as FormFieldValues;
      const answers: EnrollmentPeriodQuestionAnswer[] = [];

      /**
       * Transform the form values object to the shape expected by subsequence
       * API calls.
       */
      Object.entries(questions).forEach(([questionId, values]) => {
        if (values.answer) {
          answers.push({
            questionId,
            questionDate: values.date,
          });
        }
      });

      send({
        type: 'submitEligibilityQuestionAnswers',
        enrollmentYear: year,
        answers,
      });
    },
    [send],
  );

  return (
    <FormProvider
      formOptions={{
        mode: 'onSubmit',
        shouldUnregister: true,
        defaultValues: { questions: {} },
      }}
      onSubmit={submitHandler}
    >
      <Fields />
    </FormProvider>
  );
};
