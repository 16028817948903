import { useCallback } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Fieldset,
  HeadingText,
  Modal,
  queryHelpers,
  Radio,
  StackItem,
  StackLayout,
  useMediaQuery,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { InternalOrExternalLink } from '@leagueplatform/web-common-components';
import {
  ControlledFieldset,
  FormProvider,
  useFormContext,
  UseFormRegisterReturn,
  useInput,
  useWatch,
} from '@leagueplatform/web-common';
import { useSelector } from '../hooks/useSelector';
import { useSend } from '../hooks/useSend';
import { EligiblePlanData, SelectedPlanData } from '../types';

type PlanCardProps = {
  planId: string;
  title: string;
  pcpText: string;
  premium: string;
  detailsUrl: string;
  onSelectClick?: (selectedPlanId: string) => void;
  registerField?: UseFormRegisterReturn;
};

/**
 * Renders a single plan card. If provided `react-hook-form` field register methods,
 * will render itself as a radio element for selection.
 */
const PlanCard = ({
  planId,
  title,
  pcpText,
  premium,
  detailsUrl,
  registerField,
}: PlanCardProps) => (
  <Card.Elevated css={{ width: '350px', height: '220px' }}>
    <Card.Section>
      <StackLayout verticalAlignment="spaceBetween" css={{ height: '100%' }}>
        <HeadingText size="lg" level="3">
          {title}
        </HeadingText>
        <UtilityText size="xs" emphasis="subtle">
          {pcpText}
        </UtilityText>
        <StackItem>
          Premium you will pay:
          <HeadingText size="xxl" level="2">
            {premium}
          </HeadingText>
        </StackItem>
        <StackItem>
          <InternalOrExternalLink href={detailsUrl} isExternalUrl>
            View plan details
          </InternalOrExternalLink>
        </StackItem>
        {registerField && (
          <StackItem>
            <Radio
              id={planId}
              label="Select this plan"
              value={planId}
              {...registerField}
            />
          </StackItem>
        )}
      </StackLayout>
    </Card.Section>
  </Card.Elevated>
);

/**
 * Renders a `<PlanCard>` populated by data from the `currentPlan` state machine
 * context property.
 */
const CurrentPlan = () => {
  const {
    planId,
    planName,
    pcp: { providerName },
  } = useSelector((snapshot) => snapshot.context.currentPlan!);

  return (
    <PlanCard
      planId={planId!}
      title={planName!}
      pcpText={`Current PCP: ${providerName!.firstName} ${
        providerName!.lastName
      }`}
      premium="tbd"
      detailsUrl="tbd"
    />
  );
};

/**
 * Opens if and when submitting the user's selected plan and quesiton answers
 * resulted in ineligibility errors.
 */
const IneligibilityErrorsModal = () => {
  const formContext = useFormContext();

  const send = useSend();

  const handleSelectAnotherPlanClick = useCallback(() => {
    formContext.reset();
    send({ type: 'returnToPlanSelection' });
  }, [formContext, send]);

  const handleReviewAnswersClick = useCallback(() => {
    send({ type: 'returnToPlanSelection' });
  }, [send]);

  const isOpen = useSelector((snapshot) =>
    snapshot.matches({ selectPlan: 'awaitingDecisionOnIneligibilityErrors' }),
  );

  const ineligibiliyErrors = useSelector(
    (snapshot) => snapshot.context.planSelectionIneligibilityErrors,
  );

  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  return (
    <Modal.Root modal={!!isMobile} open={isOpen}>
      <Modal.Content
        showCloseButton={false}
        layout="standard"
        onInteractOutside={(event) => event.preventDefault()}
      >
        ineligibility errors:
        {ineligibiliyErrors?.map((error) => `${error}`)}
        <Button type="button" onClick={handleSelectAnotherPlanClick}>
          Select another plan
        </Button>
        <Button type="button" onClick={handleReviewAnswersClick}>
          Review my answers
        </Button>
      </Modal.Content>
    </Modal.Root>
  );
};

type FormFieldValues = {
  planId: string;
  [key: string]: string | string[];
};

type FieldsProps = {
  eligiblePlans: EligiblePlanData[];
};

/**
 * Renders each plan the user can select. When the user selects one of them,
 * the corresponding eligibility questions are displayed.
 */
const Fields = ({ eligiblePlans }: FieldsProps) => {
  /**
   * We register an input field with RHF so that we can pass its methods
   * to `<PlanCard>`, since we want each of those cards to show a radio button
   * for plan selection.
   *
   * This radio button can be made to "look like a button", if the design calls
   * for it.
   */
  const { field, inputValidationState } = useInput('planId', {
    required: true,
  });

  const selectedPlanId = useWatch<FormFieldValues, 'planId'>({
    name: 'planId',
  });

  return (
    <>
      <Fieldset legend="Choose a plan" {...inputValidationState}>
        <StackLayout>
          {eligiblePlans.map(
            ({
              planId,
              planName,
              pcpCoverage,
              premium,
              detailsURL,
              questions,
            }) => (
              <>
                <PlanCard
                  key={planId}
                  planId={planId}
                  title={planName}
                  pcpText={
                    pcpCoverage
                      ? 'includes your current PCP'
                      : 'does not include your current PCP'
                  }
                  premium={premium!}
                  detailsUrl={detailsURL}
                  registerField={field}
                />
                {selectedPlanId === planId &&
                  questions?.map(
                    ({
                      questionId,
                      questionLabel,
                      inputType,
                      hint,
                      isRequired,
                      options,
                    }) => {
                      const InputElement =
                        inputType === 'checkbox' ? Checkbox : Radio;
                      return (
                        <ControlledFieldset
                          key={questionId}
                          name={questionId!}
                          inputOptions={{ required: isRequired }}
                          legend={questionLabel}
                          hint={hint}
                        >
                          {options.map(({ label, value }) => (
                            <InputElement
                              name={questionId!}
                              id={`${questionId}-${value}`}
                              label={label}
                              value={value}
                            />
                          ))}
                        </ControlledFieldset>
                      );
                    },
                  )}
              </>
            ),
          )}
        </StackLayout>
      </Fieldset>
      <IneligibilityErrorsModal />
    </>
  );
};

/**
 * Shown when the user is ready to select their new plan.
 */
export const SelectPlanView = () => {
  const send = useSend();

  const eligiblePlans = useSelector(
    (snapshot) => snapshot.context.eligiblePlans!,
  );

  const submitHandler = useCallback(
    (fieldValues: FormFieldValues) => {
      const { planId, ...rest } = fieldValues;

      const qualifyingQuestionAnswers: SelectedPlanData['qualifyingQuestionAnswers'] =
        Object.entries(rest)
          .filter(([, value]) => !!value)
          .map(([name, value]) => ({
            questionId: name,
            selectedValue: typeof value === 'string' ? value : value.join(','),
          }));

      send({
        type: 'submitPlanSelection',
        selectedPlan: eligiblePlans.find((plan) => plan.planId === planId)!,
        qualifyingQuestionAnswers,
      });
    },
    [send, eligiblePlans],
  );

  return (
    <FormProvider<FormFieldValues>
      formOptions={{ shouldUnregister: true }}
      onSubmit={submitHandler}
    >
      your current plan:
      <CurrentPlan />
      Change your plan to:
      <Fields eligiblePlans={eligiblePlans} />
      <Button type="submit">Continue</Button>
    </FormProvider>
  );
};
