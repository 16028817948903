import React from 'react';
import {
  HeadingText,
  ParagraphText,
  UtilityText,
  StackLayout,
  StackItem,
  Checkbox,
} from '@leagueplatform/genesis-core';
import {
  useGetUserProfile,
  useForm,
  type FieldValues,
} from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { BasePage } from 'components/base-page.component';
import { FullPageContentSection } from 'components/content-section/content-section.component';
import { ProfilePictureForm } from 'components/forms/profile-picture-form.component';
import { CTAFooter } from 'components/cta-footer/cta-footer.component';
import { useUserName } from 'hooks/user-user-name/use-user-name.hook';
import { CONTENT_MAX_WIDTH, CTA_FOOTER_HEIGHT } from 'common/constants/ui';

interface AccountSetupFieldValues extends FieldValues {
  onlineMaterialsConsent: boolean;
}

export const AccountSetupPage = ({
  onCompleteAccountSetup,
}: {
  onCompleteAccountSetup: () => void;
}) => {
  const { data: userData } = useGetUserProfile();
  const { phoneNumber, email } = userData || {};
  const { userFullName } = useUserName();
  const { formatMessage } = useIntl();
  const { register, handleSubmit } = useForm<AccountSetupFieldValues>();

  const handleFormSubmit = () => {
    // TODO [SCAN-765] Account Setup API Integration
    // TODO [SCAN-765] on successful mutation, forward the user to the next Onboarding view (onCompleteAccountSetup)
    onCompleteAccountSetup();
  };

  return (
    <BasePage
      isPrivatePage={false}
      css={{
        padding: '$four',
        display: 'flex',
        paddingBottom: `calc($twoAndHalf + ${CTA_FOOTER_HEIGHT} + $two)`,
        '@mobile': {
          paddingX: '$none',
          paddingTop: '$none',
          padding: '$none',
          flexDirection: 'column',
        },
      }}
    >
      <FullPageContentSection
        css={{
          maxWidth: CONTENT_MAX_WIDTH,
        }}
        spacing="$two"
        verticalAlignment="center"
      >
        <StackItem horizontalAlignment="center" css={{ alignSelf: 'center' }}>
          <HeadingText size="lg" level="2" as="h1">
            {formatMessage({ id: 'STR_ACCOUNT_SETUP_HEADING' })}
          </HeadingText>
          <ParagraphText>
            {formatMessage({ id: 'STR_ACCOUNT_SETUP_SUBHEADING' })}
          </ParagraphText>
        </StackItem>
        <StackItem horizontalAlignment="center" css={{ alignSelf: 'center' }}>
          <ProfilePictureForm />
          <HeadingText size="lg" level="2" as="h2" css={{ marginTop: '$half' }}>
            {userFullName}
          </HeadingText>
        </StackItem>
        <StackItem>
          <StackLayout spacing="$half">
            <HeadingText size="md" level="4" as="h2">
              {formatMessage({ id: 'STR_CONTACT_INFO' })}
            </HeadingText>
            <StackLayout orientation="vertical" spacing="$quarter">
              <UtilityText size="sm">
                {formatMessage({ id: 'STR_EMAIL' })}
              </UtilityText>
              <UtilityText emphasis="emphasized">{email}</UtilityText>
            </StackLayout>
            <StackLayout orientation="vertical" spacing="$quarter">
              <UtilityText size="sm">
                {formatMessage({ id: 'STR_PHONE_NUMBER' })}
              </UtilityText>
              <UtilityText emphasis="emphasized">{phoneNumber}</UtilityText>
            </StackLayout>
          </StackLayout>
        </StackItem>
        <StackItem>
          <StackLayout as="form" onSubmit={handleSubmit(handleFormSubmit)}>
            <Checkbox
              id="onlineMaterialsConsent-checkbox"
              label={formatMessage({
                id: 'STR_ONLINE_MATERIALS_CONSENT_LABEL',
              })}
              {...register('onlineMaterialsConsent')}
            />
            <CTAFooter
              showPrimaryBtn
              primaryBtnProps={{
                type: 'submit',
              }}
              contentWidth={CONTENT_MAX_WIDTH}
            />
          </StackLayout>
        </StackItem>
      </FullPageContentSection>
    </BasePage>
  );
};
