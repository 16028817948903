import { assertEvent, assign, setup } from 'xstate';
import {
  SaveSessionError,
  type Context,
  type Event,
} from './pbp-change-machine.types';
import {
  fetchInitalContextProperties,
  startSession,
  saveSession,
  fetchEnrollmentPeriods,
  fetchPlans,
  fetchResumedSessionData,
  fetchPaymentOptions,
} from './actors';
import {
  formatCommunicationOptionForContext,
  formatEnrollmentAnswersForContext,
  formatQualifyingAnswersForContext,
} from '../utils';

export const pbpChangeMachine = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
  },
  actors: {
    fetchInitalContextProperties,
    startSession,
    saveSession,
    fetchEnrollmentPeriods,
    fetchPlans,
    fetchResumedSessionData,
    fetchPaymentOptions,
  },
  actions: {
    assignSessionResumeProperties: assign(() => {
      const params = new URLSearchParams(window.location.search);

      return {
        enrollmentId: params.get('enrollmentId') ?? undefined,
        selectedPCPId: params.get('selectedPCPId') ?? undefined,
      };
    }),
    onError: assign(({ event }) =>
      'error' in event ? { error: event.error } : {},
    ),
  },
  guards: {
    hasEnrollmentId: ({ context }) => !!context.enrollmentId,
    isSplitCountyResponse: ({ event }) =>
      `error` in event &&
      /**
       * TODO: esablish actual logic to determine whether the error
       * is a "split county" error or not. This is just a placeholder.
       */
      event.error instanceof SaveSessionError &&
      event.error.errors[0].errorCode === 'splitCounty',
    isIneligibilityErrorResponse: ({ event }) =>
      `error` in event &&
      /**
       * TODO: esablish actual logic to determine whether the error
       * is a "split county" error or not. This is just a placeholder.
       */ event.error instanceof SaveSessionError &&
      event.error.errors[0].errorCode === 'ineligible',
    selectedPlanHasPremium: ({ context }) => !!context.selectedPlan?.premium,
  },
}).createMachine({
  initial: 'initializing',
  on: {
    error: {
      target: '.error',
      actions: 'onError',
    },
  },
  states: {
    initializing: {
      entry: 'assignSessionResumeProperties',
      initial: 'fetchInitalContextProperties',
      states: {
        fetchInitalContextProperties: {
          invoke: {
            src: 'fetchInitalContextProperties',
            onDone: [
              {
                /**
                 * If our initlal params include an enrollment ID, it means that
                 * the user has just returned back from the "Select PCP" external
                 * flow, so we should prepare to send them directly back to the
                 * "your care team" step from which they came.
                 */
                guard: 'hasEnrollmentId',
                target: 'fetchingResumedSessionData',
                actions: assign(({ event }) => event.output),
              },
              {
                /**
                 * Otherwise, start a new session.
                 */
                target: 'startingSession',
                actions: assign(({ event }) => event.output),
              },
            ],
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
        fetchingResumedSessionData: {
          invoke: {
            src: 'fetchResumedSessionData',
            input: ({ context }) => ({
              userId: context.userId!,
              enrollmentId: context.enrollmentId!,
              selectedPCPId: context.selectedPCPId,
              communicationOptions: context.communicationOptions!,
            }),
            onDone: {
              target: '#yourCareTeam',
              actions: assign(({ event }) => event.output),
            },
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
        startingSession: {
          invoke: {
            src: 'startSession',
            input: ({ context }) => ({ userId: context.userId! }),
            onDone: {
              target: 'gettingEnrollmentPeriods',
              actions: assign({ enrollmentId: ({ event }) => event.output }),
            },
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
        gettingEnrollmentPeriods: {
          invoke: {
            src: 'fetchEnrollmentPeriods',
            onDone: {
              target: '#eligibilityQuestions',
              actions: assign({
                enrollmentPeriods: ({ event }) => event.output,
              }),
            },
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
      },
    },
    eligibilityQuestions: {
      id: 'eligibilityQuestions',
      initial: 'awaitingSubmission',
      states: {
        awaitingSubmission: {
          on: {
            submitEligibilityQuestionAnswers: {
              target: 'savingSession',
              actions: assign(({ event, context }) => {
                const { questions, ...selectedEnrollmentPeriodProperties } =
                  event.enrollmentYear
                    ? /**
                       * If we received an "enrollment year" from the user's submission, we will use
                       * it to figure out which of the enrollment periods delivered by
                       * the API the submission is against.
                       *
                       * Otherwise, we assume there was no selection because we only got
                       * one enrollment period in the first place.
                       */
                      context.enrollmentPeriods!.find(
                        (enrollmentPeriod) =>
                          enrollmentPeriod.enrollmentYear ===
                          Number(event.enrollmentYear),
                      )!
                    : context.enrollmentPeriods![0];

                return {
                  selectedEnrollmentPeriod: {
                    ...selectedEnrollmentPeriodProperties,
                    answers: questions
                      ? formatEnrollmentAnswersForContext(
                          questions,
                          event.answers,
                        )
                      : undefined,
                  },
                };
              }),
            },
          },
        },
        savingSession: {
          invoke: {
            src: 'saveSession',
            input: ({ event, context }) => {
              assertEvent(event, 'submitEligibilityQuestionAnswers');

              const {
                id: enrollmentPeriodId,
                enrollmentYear,
                effectiveDate,
              } = context.selectedEnrollmentPeriod!;

              return {
                userId: context.userId!,
                enrollmentSessionId: context.enrollmentId!,
                saveAction: 'SaveEnrollmentPeriod',
                enrollmentPeriodData: {
                  enrollmentPeriodId,
                  enrollmentYear,
                  effectiveDate,
                  questionAnswers: event.answers,
                },
              };
            },
            onDone: '#addressInfo',
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
      },
    },
    addressInfo: {
      id: 'addressInfo',
      initial: 'awaitingSubmission',
      states: {
        awaitingSubmission: {
          on: {
            submitAddressInfoNotMoving: 'fetchingPlans',
            submitAddressInfoMoving: {
              target: 'savingSession',
              actions: assign({
                addressInformation: ({ event }) => {
                  const residential = event.addresses.find(
                    (address) => address.addressType === 'residential',
                  )!;
                  const mailing = event.addresses.find(
                    (address) => address.addressType === 'mailing',
                  );

                  return {
                    residential,
                    mailing,
                  };
                },
              }),
            },
          },
        },
        savingSession: {
          invoke: {
            src: 'saveSession',
            input: ({ context, event }) => {
              assertEvent(event, 'submitAddressInfoMoving');

              return {
                userId: context.userId!,
                enrollmentSessionId: context.enrollmentId!,
                saveAction: 'SaveAddresses',
                addresses: event.addresses,
              };
            },
            onDone: 'fetchingPlans',
            onError: [
              {
                guard: 'isSplitCountyResponse',
                target: 'awaitingSubmission',
                actions: assign({
                  splitCountyOptions: ({ event }) =>
                    /**
                     * TODO: establish actual logic to parse the counties
                     * to offer as selection options. This is just a placeholder.
                     */
                    (
                      event.error as SaveSessionError
                    ).errors[0].errorDescription!.split(','),
                }),
              },
              {
                target: '#error',
                actions: 'onError',
              },
            ],
          },
        },
        fetchingPlans: {
          invoke: {
            src: 'fetchPlans',
            input: ({ context }) => ({
              userId: context.userId!,
              enrollmentId: context.enrollmentId!,
            }),
            onDone: {
              target: '#selectPlan',
              actions: assign(({ event }) => event.output),
            },
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
      },
    },
    selectPlan: {
      id: 'selectPlan',
      initial: 'awaitingSubmission',
      states: {
        awaitingSubmission: {
          on: {
            submitPlanSelection: {
              target: 'savingSession',
              /**
               * Store the selected plan in Context, becasue we will need to
               * refer to it when displaying the "Your Care Team" step later.
               */
              actions: assign(({ event, context }) => {
                const selectedPCP = event.selectedPlan.pcpCoverage
                  ? context.currentPlan!.pcp
                  : undefined;

                const { questions, ...selectedPlanProperties } =
                  event.selectedPlan;

                return {
                  selectedPlan: {
                    ...selectedPlanProperties,
                    answers:
                      event.qualifyingQuestionAnswers &&
                      formatQualifyingAnswersForContext(
                        questions!,
                        event.qualifyingQuestionAnswers,
                      ),
                  },
                  selectedPCP,
                };
              }),
            },
          },
        },

        savingSession: {
          invoke: {
            src: 'saveSession',
            input: ({ context, event }) => {
              assertEvent(event, 'submitPlanSelection');

              return {
                userId: context.userId!,
                enrollmentSessionId: context.enrollmentId!,
                saveAction: 'SavePlanData',
                planData: {
                  planId: event.selectedPlan.planId,
                  qualifyingQuestionAnswers: event.qualifyingQuestionAnswers,
                },
              };
            },
            onDone: '#yourCareTeam',
            onError: [
              {
                guard: 'isIneligibilityErrorResponse',
                target: 'awaitingDecisionOnIneligibilityErrors',
                actions: assign({
                  planSelectionIneligibilityErrors: ({ event }) =>
                    /**
                     * TODO: establish actual logic to parse the eligibility
                     * validation errors to show.
                     */
                    (event.error as SaveSessionError).errors,
                }),
              },
              {
                target: '#error',
                actions: 'onError',
              },
            ],
          },
        },
        awaitingDecisionOnIneligibilityErrors: {
          on: {
            returnToPlanSelection: 'awaitingSubmission',
          },
        },
      },
    },
    yourCareTeam: {
      id: 'yourCareTeam',
      initial: 'awaitingSubmissionOrContinue',
      states: {
        awaitingSubmissionOrContinue: {
          on: {
            submitDentalInformation: {
              target: 'savingSession',
              actions: assign({
                dentalInformation: ({ event }) => event.dentalInformation,
              }),
            },
            continue: '#communications',
          },
        },
        savingSession: {
          invoke: {
            src: 'saveSession',
            input: ({ event, context }) => {
              assertEvent(event, 'submitDentalInformation');

              return {
                userId: context.userId!,
                enrollmentSessionId: context.enrollmentId!,
                saveAction: 'SaveDentalData',
                dentalData: {
                  // TODO: figure out discrepancy between field names in design and API response shape
                  dentalProviderNumber:
                    event.dentalInformation.dentalProviderNumber,
                  dentalOfficeName: event.dentalInformation.dentalOfficeName,
                },
              };
            },
            onDone: '#communications',
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
      },
    },
    communications: {
      id: 'communications',
      initial: 'awaitingSubmission',
      states: {
        awaitingSubmission: {
          on: {
            submitCommunicationSelection: {
              target: 'savingSession',
              actions: assign({
                selectedCommunicationOption: ({ context, event }) =>
                  formatCommunicationOptionForContext(
                    context.communicationOptions!,
                    event.format,
                  ),
              }),
            },
            continue: [
              {
                guard: 'selectedPlanHasPremium',
                target: 'fetchingPaymentOptions',
              },
              '#review',
            ],
          },
        },
        savingSession: {
          invoke: {
            src: 'saveSession',
            input: ({ event, context }) => {
              assertEvent(event, 'submitCommunicationSelection');

              return {
                userId: context.userId!,
                enrollmentSessionId: context.enrollmentId!,
                saveAction: 'SaveCommunicationData',
                communicationData: {
                  communicationValue: event.format,
                },
              };
            },
            onDone: [
              {
                guard: 'selectedPlanHasPremium',
                target: 'fetchingPaymentOptions',
              },
              '#review',
            ],
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
        fetchingPaymentOptions: {
          invoke: {
            src: 'fetchPaymentOptions',
            input: ({ context }) => ({
              userId: context.userId!,
              enrollmentId: context.enrollmentId!,
            }),
            onDone: {
              target: '#paymentInformation',
              actions: assign({ paymentOptions: ({ event }) => event.output }),
            },
          },
        },
      },
    },
    paymentInformation: {
      id: 'paymentInformation',
      initial: 'awaitingSubmission',
      states: {
        awaitingSubmission: {
          on: {
            submitPaymentOptionSelection: {
              target: 'savingSession',
              actions: assign({
                selectedPaymentOption: ({ event }) =>
                  event.selectedPaymentOption,
              }),
            },
          },
        },
        savingSession: {
          invoke: {
            src: 'saveSession',
            input: ({ event, context }) => {
              assertEvent(event, 'submitPaymentOptionSelection');

              return {
                userId: context.userId!,
                enrollmentSessionId: context.enrollmentId!,
                saveAction: 'SavePaymentData',
                paymentData: {
                  paymentOptionValue: event.selectedPaymentOption.optionValue,
                  paymentOptionQuestionId:
                    event.selectedPaymentOption.optionQuestion
                      ?.optionQuestionValue,
                },
              };
            },
            onDone: '#review',
            onError: {
              target: '#error',
              actions: 'onError',
            },
          },
        },
      },
    },
    review: {
      id: 'review',
      on: {
        continue: '#eSign',
      },
    },
    eSign: {
      id: 'eSign',
    },
    error: {
      id: 'error',
      type: 'final',
    },
  },
});

export type PBPChangeMachine = typeof pbpChangeMachine;
