import { StackLayout, styled } from '@leagueplatform/genesis-core';
import { CTA_FOOTER_HEIGHT } from 'common/constants/ui';

export const ContentSection = styled(StackLayout, {
  backgroundColor: '$surfaceCardSecondary',
  padding: '$twoAndHalf',
  boxShadow: '$card',
  margin: 'auto',
});

export const FullPageContentSection = styled(ContentSection, {
  '@mobile': {
    margin: '$none',
    boxShadow: '$none',
    paddingBottom: `calc($twoAndHalf + ${CTA_FOOTER_HEIGHT})`,
    justifyContent: 'center',
  },
});
