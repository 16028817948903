export const APP_ID = 'scan-web';
export const TENANT_ID = 'scan';
export const COMPANY_NAME = 'SCAN Health Plan';
export const ROUTE_PATHS = {
  HOME: '/home',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  PERSONAL_INFO: '/settings/personal-information',
  COMMUNICATION_PREFERENCES: '/communication-preferences',
};
export const LOCAL_STORAGE_KEYS = {
  FEATURE_HIGHLIGHTS_COMPLETED: 'feature_highlights_completed',
};
export const ADDRESS_TYPES = {
  RESIDENTIAL: 'residential',
  MAILING: 'mailing',
};
export const ADDRESS_FORM_ELEMENT_IDS = {
  STREET_ADDRESS: 'streetAddress',
  STREET_ADDRESS_2: 'streetAddress2',
  CITY: 'city',
  COUNTY: 'county',
  ZIP_CODE: 'zipCode',
  STATE: 'state',
  EFFECTIVE_DATE: 'effectiveDate',
};

export const STATE_ABBREVIATIONS = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'scan-web',
};

// Masonry v1 or v1.5 containers
export const MASONRY_WS_CONTAINERS = {
  HOME_PAGE: 'scan_homepage',
  CARE_HOME_PAGE: 'scan_care',
};

export const FORM_LEGEND_STYLE = {
  '.GDS-fieldset-legend': {
    typography: '$headingThree',
    marginBottom: '$one',
  },
};
