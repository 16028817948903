import { fromPromise } from 'xstate';
import { Auth } from '@leagueplatform/auth';
import { pbpChangeClient } from '../../../api/pbp-change-client';
import { memberClient } from '../../../api/member-client';
import {
  EligiblePlanData,
  SessionContext,
  SessionSaveRequestData,
  CurrentPlanWithPCP,
  PaymentOption,
} from '../types';
import { Context, SaveSessionError } from './pbp-change-machine.types';
import {
  formatEnrollmentAnswersForContext,
  formatQualifyingAnswersForContext,
} from '../utils';

/**
 * Prepares machine context properties used throughout the lifetime of the machine,
 * including the user ID and, potentially, enrollment ID and PCP ID if the user
 * had just arrived back from the "Select PCP" external flow.
 */
export const fetchInitalContextProperties = fromPromise(async () => {
  const userId = await Auth.getUserId();

  if (!userId) {
    throw new Error('Could not get User ID from IDP token!');
  }

  const { error, data } = await pbpChangeClient.GET('/communication-options');

  if (error || !data.data) {
    throw new Error('Could not fetch communication options!');
  }

  return {
    userId,
    communicationOptions: data.data,
  };
});

/**
 * Given a user ID, will POST to SCAN's `start-session` API endpoing
 * and return the enrollment ID provided.
 */
export const startSession = fromPromise<string, { userId: string }>(
  async ({ input }) => {
    const { error, data } = await pbpChangeClient.POST('/start-session', {
      body: {
        data: {
          userId: input.userId,
        },
      },
    });

    if (error) {
      throw new Error(`Failed to start session! ${error}`);
    }

    return data.data!.enrollmentId;
  },
);

/**
 * Given a session context, a save action, and data corresponding to that save action,
 * will send the data to the `/save-session` endpoint to persist it.
 */
export const saveSession = fromPromise<void, SessionSaveRequestData>(
  async ({ input }) => {
    const { error } = await pbpChangeClient.POST('/save-session', {
      body: {
        data: input,
      },
    });

    if (error) {
      if (error.data?.length) {
        throw new SaveSessionError(error.data);
      }

      throw new Error(`Failed to save session! ${error}`);
    }
  },
);

/**
 * GETs all applicable enrollment periods from SCAN's `enrollment-periods` API endpoing.
 */
export const fetchEnrollmentPeriods = fromPromise(async () => {
  const { error, data } = await pbpChangeClient.GET('/enrollment-periods');

  if (error) {
    throw new Error(`Failed to get enrollment periods! ${error}`);
  }

  /**
   * We assume that all enrollment periods returned by the API are of the same type
   * ("id"), since the designs show that we describe that type above the year selector
   * field. Just in case, we will throw an error if NOT all enrollment periods
   * are of the same type.
   */
  const enrollmentPeriodIDs = new Set(data.data!.map(({ id }) => id));

  if (enrollmentPeriodIDs.size > 1) {
    throw new Error(
      `Received multiple enrollment periods with different types!`,
    );
  }

  return data.data!;
});

/**
 * This data is needed by both the `fetchPlans` and the `fetchResumedSessionData`
 * actors, so we abstract it for reuse.
 */
const doFetchPlans = async (sessionContext: SessionContext) => {
  const [eligibility, eligiblePlans] = await Promise.all([
    memberClient.GET('/members/{id}/eligibility', {
      params: {
        path: {
          id: sessionContext.userId,
        },
      },
    }),
    pbpChangeClient.POST('/get-plans', {
      body: {
        data: sessionContext,
      },
    }),
  ]);

  if (
    eligibility.error ||
    eligiblePlans.error ||
    !eligibility.data.plans ||
    !eligiblePlans.data.data
  ) {
    throw new Error(`Failed to get plan data!`);
  }

  const currentPlan = eligibility.data.plans.find(
    (plan) => plan.planStatus === 'ACTIVE',
  );

  if (!currentPlan) {
    throw new Error(`User has no active plans!`);
  }

  if (!eligibility.data.memberProviders) {
    throw new Error(`User has no providers!`);
  }

  const pcp = eligibility.data.memberProviders.find(
    (provider) => provider.providerRole === 'PCP',
  );

  if (!pcp?.provider) {
    throw new Error(`User has no PCP!`);
  }

  return {
    currentPlan: {
      ...currentPlan,
      pcp: pcp?.provider,
    },
    eligiblePlans: eligiblePlans.data.data,
  };
};

export const fetchPlans = fromPromise<
  { currentPlan: CurrentPlanWithPCP; eligiblePlans: EligiblePlanData[] },
  SessionContext
>(({ input }) => doFetchPlans(input));

type PickContext<T extends keyof Context> = {
  [key in T]-?: Context[key];
};

type PickNonNullableContext<T extends keyof Context> = {
  [key in T]-?: NonNullable<Context[key]>;
};

/**
 * Upon returning from the external "select new PCP" flow, and given the Enrollment ID
 * and selected PCP ID provided by that flow, will assemble all the data required
 * to display the "Your Care Team" step in our flow.
 */
export const fetchResumedSessionData = fromPromise<
  PickNonNullableContext<'selectedEnrollmentPeriod' | 'selectedPlan'> &
    PickContext<'selectedPCP' | 'addressInformation'>,
  SessionContext & {
    selectedPCPId?: string;
  } & PickNonNullableContext<'communicationOptions'>
>(async ({ input }) => {
  const [sessionData, enrollmentPeriods, plans, providers] = await Promise.all([
    pbpChangeClient.POST('/get-session', {
      body: {
        data: {
          userId: input.userId,
          enrollmentId: input.enrollmentId,
        },
      },
    }),
    pbpChangeClient.GET('/enrollment-periods'),
    doFetchPlans(input),

    // TODO: this is a placeholder for an eventual "/get-provider" endpoint.
    memberClient.GET('/members/{id}/eligibility', {
      params: {
        path: {
          id: input.userId,
        },
      },
    }),
  ]);

  if (
    sessionData.error ||
    enrollmentPeriods.error ||
    providers.error ||
    !sessionData.data.data ||
    !enrollmentPeriods.data.data ||
    !providers.data
  ) {
    throw new Error('Could not restore session!');
  }

  const { enrollmentPeriodId, questionAnswers } =
    sessionData.data.data.enrollmentPeriodData!;

  const {
    questions: enrollmentQuestions,
    ...selectedEnrollmentPeriodProperties
  } = enrollmentPeriods.data.data.find(
    (period) => period.id === enrollmentPeriodId,
  )!;

  const selectedEnrollmentPeriod = {
    ...selectedEnrollmentPeriodProperties,
    answers:
      questionAnswers &&
      formatEnrollmentAnswersForContext(enrollmentQuestions!, questionAnswers),
  };

  const addressInformation = sessionData.data.data.addresses
    ? {
        residential: sessionData.data.data.addresses.find(
          (address) => address.addressType === 'residential',
        )!,
        mailing: sessionData.data.data.addresses.find(
          (address) => address.addressType === 'mailing',
        ),
      }
    : undefined;

  const { currentPlan, eligiblePlans } = plans;

  const { questions: qualifyingQuestions, ...selectedPlanProperties } =
    eligiblePlans.find(
      ({ planId }) => planId === sessionData.data.data!.planData?.planId,
    )!;

  const selectedPlan = {
    ...selectedPlanProperties,
    answers:
      sessionData.data.data!.planData?.qualifyingQuestionAnswers &&
      formatQualifyingAnswersForContext(
        qualifyingQuestions!,
        sessionData.data.data!.planData?.qualifyingQuestionAnswers,
      ),
  };

  // TODO: replace with actual `/get-provider`-ish API. This is a placeholder.
  const selectedPCP = providers.data.memberProviders?.find(
    (provider) => provider.provider?.id === input.selectedPCPId,
  )?.provider;

  return {
    selectedEnrollmentPeriod,
    addressInformation,
    currentPlan,
    selectedPlan,
    selectedPCP,
  };
});

export const fetchPaymentOptions = fromPromise<PaymentOption[], SessionContext>(
  async ({ input }) => {
    const { error, data } = await pbpChangeClient.POST('/payment-options', {
      body: {
        data: input,
      },
    });

    if (error || !data.data) {
      throw new Error('Could not fetch payment options!');
    }

    return data.data;
  },
);
