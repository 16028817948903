import {
  useFormContext,
  type RegisterOptions,
} from '@leagueplatform/web-common';
import type { PropsWithChildren } from 'react';
import {
  InputHint,
  InputLabel,
  InputStatusMessage,
  Box,
} from '@leagueplatform/genesis-core';

export interface RHFInputWrapperProps {
  id: string;
  label: string;
  hint?: string;
  required?: boolean;
}

export type MergeWithRHFInputWrapper<T> = Omit<T, keyof RHFInputWrapperProps> &
  RHFInputWrapperProps & {
    registerOptions?: RegisterOptions;
  };

export const RHFInputWrapper = ({
  id,
  label,
  hint,
  children,
  required,
}: PropsWithChildren<RHFInputWrapperProps>) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <InputLabel
        htmlFor={id}
        required={required}
        css={{ marginBlockEnd: '$half' }}
      >
        {label}
      </InputLabel>

      {children}
      {hint && (
        <InputHint id={`${id}-hint`} css={{ marginBlockStart: '$half' }}>
          {hint}
        </InputHint>
      )}
      {errors?.[id]?.message && (
        <InputStatusMessage inputStatus="error" id={`${id}-error`}>
          {errors[id]?.message as string}
        </InputStatusMessage>
      )}
    </Box>
  );
};
