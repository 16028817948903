import React from 'react';
import {
  HeadingText,
  ParagraphText,
  Button,
  StackLayout,
  StackItem,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { FullPageContentSection } from 'components/content-section/content-section.component';
import { BasePage } from 'components/base-page.component';
import HighFiveCelebrationIllustration from 'assets/high-five-celebration-illustration.png';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants/constants';
import { CONTENT_MAX_WIDTH, CTA_FOOTER_HEIGHT } from 'common/constants/ui';

export const AccountSetupCompletePage = ({
  onOnboardingComplete,
}: {
  onOnboardingComplete: () => void;
}) => {
  const { formatMessage } = useIntl();
  return (
    <BasePage
      isPrivatePage={false}
      css={{
        padding: '$four',
        display: 'flex',
        paddingBottom: `calc($twoAndHalf + ${CTA_FOOTER_HEIGHT} + $two)`,
        '@mobile': {
          paddingX: '$none',
          paddingTop: '$none',
          padding: '$none',
          flexDirection: 'column',
        },
      }}
    >
      <FullPageContentSection
        css={{ maxWidth: CONTENT_MAX_WIDTH, paddingX: '$five' }}
        spacing="$two"
        horizontalAlignment="center"
        verticalAlignment="center"
      >
        <img src={HighFiveCelebrationIllustration} aria-hidden alt="" />
        <StackItem>
          <StackLayout horizontalAlignment="center">
            <HeadingText level="1" size="lg">
              {formatMessage({ id: 'STR_ACCOUNT_SETUP_COMPLETE_HEADING' })}
            </HeadingText>
            <ParagraphText css={{ textAlign: 'center' }}>
              {formatMessage({ id: 'STR_ACCOUNT_SETUP_COMPLETE_BODY' })}
            </ParagraphText>
          </StackLayout>
        </StackItem>
        <StackItem>
          <StackLayout
            spacing="$half"
            horizontalAlignment="center"
            css={{ width: '100%' }}
          >
            <Button
              as="a"
              width="fillContainer"
              onClick={() => onOnboardingComplete()}
              href={getModulePath(LEAGUE_MODULE_NAMES.assessment)}
              css={{ alignItems: 'center' }}
            >
              {formatMessage({ id: 'STR_START_HEALTH_ASSESSMENT' })}
            </Button>
            <Button
              as="a"
              width="fillContainer"
              quiet
              priority="tertiary"
              onClick={() => onOnboardingComplete()}
              href={ROUTE_PATHS.HOME}
            >
              {formatMessage({ id: 'STR_NOT_NOW' })}
            </Button>
          </StackLayout>
        </StackItem>
      </FullPageContentSection>
    </BasePage>
  );
};
