import {
  CommunicationOption,
  EligiblePlanData,
  EnrollmentPeriod,
  EnrollmentPeriodQuestionAnswer,
  PaymentOption,
  SelectedEnrollmentPeriod,
  SelectedPaymentOption,
  SelectedPlan,
  SelectedPlanData,
} from './types';

export const formatEnrollmentAnswersForContext = (
  enrollmentPeriodQuestions: NonNullable<EnrollmentPeriod['questions']>,
  answers: EnrollmentPeriodQuestionAnswer[],
): NonNullable<SelectedEnrollmentPeriod['answers']> =>
  answers.map((answer) => {
    const question = enrollmentPeriodQuestions.find(
      ({ id }) => id === answer.questionId,
    )!;

    return {
      ...question,
      questionDate: answer.questionDate,
    };
  });

export const formatQualifyingAnswersForContext = (
  qualifyingQuestions: NonNullable<EligiblePlanData['questions']>,
  answers: NonNullable<SelectedPlanData['qualifyingQuestionAnswers']>,
): NonNullable<SelectedPlan['answers']> =>
  answers!.map((answer) => {
    const { options, ...questionProperties } = qualifyingQuestions.find(
      ({ questionId }) => answer.questionId === questionId,
    )!;

    const selectedValues = answer.selectedValue?.split(',');

    return {
      ...questionProperties,
      options: options.filter((option) =>
        selectedValues?.includes(option.value),
      ),
    };
  });

export const formatCommunicationOptionForContext = (
  communicationOptions: CommunicationOption[],
  selectedCommunicationOption: CommunicationOption['optionValue'],
): CommunicationOption =>
  communicationOptions.find(
    (option) => option.optionValue === selectedCommunicationOption,
  )!;

export const formatPaymentOptionForContext = (
  paymentOptions: PaymentOption[],
  selectedPaymentOption: PaymentOption['optionValue'],
  selectedPaymentOptionQuestion?: NonNullable<
    PaymentOption['optionQuestions']
  >[number]['optionQuestionValue'],
): SelectedPaymentOption => {
  const { optionValue, optionLabel, optionQuestions } = paymentOptions.find(
    (option) => option.optionValue === selectedPaymentOption,
  )!;
  const optionQuestion = optionQuestions?.find(
    (question) =>
      question.optionQuestionValue === selectedPaymentOptionQuestion,
  );

  return {
    optionValue,
    optionLabel,
    optionQuestion,
  };
};
