import React from 'react';
import {
  HeadingText,
  ParagraphText,
  UtilityText,
  styled,
  StackLayout,
} from '@leagueplatform/genesis-core';

type ListedParagraphType = { [key: string]: string };
const textCommTermsListedParagraphs: ListedParagraphType[] = [
  {
    key: 'consent',
    text: 'Consent: By providing your phone number, you consent to receive email communications from us, including newsletters, updates, promotions, and other relevant information.',
  },
  {
    key: 'privacy',
    text: 'Privacy: Your phone number and any other personal information will be used in accordance with our Privacy Policy. We are committed to protecting your privacy and will not share your information with third parties without your consent.',
  },
  {
    key: 'unsubscribe',
    text: 'Unsubscribe: You may opt out of receiving email communications from us at any time by clicking the "unsubscribe" link provided in every email. Your request will be processed promptly, and you will no longer receive our emails.',
  },
  {
    key: 'frequency',
    text: 'Frequency: We strive to keep our email communications relevant and will not overwhelm your inbox. However, the frequency of emails may vary based on your preferences and our content schedule.',
  },
  {
    key: 'content-accuracy',
    text: 'Content Accuracy: We make every effort to ensure that the content of our emails is accurate and up-to-date. However, we do not guarantee the accuracy, completeness, or reliability of any information provided.',
  },
  {
    key: 'changes-to-terms',
    text: 'Changes to Terms: We reserve the right to modify these terms and conditions at any time. Any changes will be communicated to you via email or through our website.',
  },
  {
    key: 'contact-us',
    text: 'Contact Us: If you have any questions or concerns about these terms and conditions, please contact us at [your contact email].',
  },
];

const OrderedList = styled('ol', {
  listStyle: 'auto',
  marginLeft: '$oneAndHalf',
  typography: '$bodyTwo',
  color: '$surfaceTextSubdued',
});

// TODO [SCAN-764] update to use final copy rather than placeholder
export const TextCommunicationTermsContent = () => (
  <StackLayout spacing="$one">
    <HeadingText size="xl" level="2" css={{ alignSelf: 'center' }}>
      Text Communications Terms & Conditions
    </HeadingText>
    <ParagraphText css={{ fontWeight: 700 }}>
      By subscribing to our text communications, you agree to the following
      terms and conditions:
    </ParagraphText>
    <OrderedList>
      {textCommTermsListedParagraphs.map((paragraph: ListedParagraphType) => (
        <li key={paragraph.key}>
          <UtilityText
            css={{ typography: '$bodyTwo', color: '$surfaceTextSubdued' }}
          >
            {paragraph.text}
          </UtilityText>
        </li>
      ))}
    </OrderedList>
    <ParagraphText>
      By subscribing to our email communications, you acknowledge that you have
      read, understood, and agreed to these terms and conditions.
    </ParagraphText>
  </StackLayout>
);
